import React from 'react';
import { 
  ChartContainer, PoweredByContainer, PoweredByTitle, ImagesContainer, 
  RiskDisclosure, Image
} from './ChartElements';
import Image1 from '../../images/Rithmic.png';
import Image2 from '../../images/quantower.png';



const ChartSection = () => {
  return (
    <>
      <ChartContainer id="charts">
        <PoweredByContainer>
          <PoweredByTitle>OUR PARTNERS:</PoweredByTitle>
          <ImagesContainer>
            <Image src={Image1} alt="Humble Funding" />
            <Image src={Image2} alt="Humble Funding" />
          </ImagesContainer>
          <RiskDisclosure>
            HUMBLE Futures FUNDING RISK DISCLOSURE:
            Trading futures and forex involves significant risk and may not be suitable for all investors. It is possible to lose more than your initial investment. Only use risk capital—money you can afford to lose without affecting your financial security or lifestyle—for trading. Trading should be considered only by those with sufficient risk capital. Past performance does not guarantee future results.          </RiskDisclosure>
            <RiskDisclosure>
            CFTC RULE 4.41:
            Hypothetical or simulated performance results have specific limitations. Unlike actual trading records, simulated results do not reflect real trades. Since the trades have not been executed, these results might understate or overstate the impact of certain market factors, such as liquidity constraints. Simulated trading programs are generally designed with the advantage of hindsight. No assurance is given that any account will achieve similar profits or losses to those shown.
            Testimonials on this website may not represent the experiences of all clients or customers and do not guarantee future performance or success. No claim is made that any evaluation account will achieve profits or losses comparable to those presented.</RiskDisclosure>
          <RiskDisclosure>
            CERTIFICATE USAGE POLICY:
            At Humble Futures Funding, we proudly highlight the achievements of our traders who have successfully passed evaluations or received payouts. These accomplishments may be featured in our social media and marketing efforts, displaying names and payout details. If you prefer to keep your achievements private, please contact Humble Futures Funding support to request the exclusion of your certificate from our public materials.          </RiskDisclosure>
          <RiskDisclosure>
            AVAILABLE INSTRUMENTS:
            Humble FUTURES Funding customers are authorized to trade Futures products on the CME, COMEX, NYMEX, and CBOT exchanges. Trading in Spot Currencies, CFDs, Stocks, Options, and Cryptocurrencies is not permitted or supported through our program vendors or platforms.
          </RiskDisclosure>
          <RiskDisclosure>
            COMPENSATION DISCLOSURE:
            All trades presented for compensation to Humble Futures Funding customers should be considered hypothetical and may not necessarily be replicated in a live trading account. PA Accounts may include simulated, live, or copied accounts. Testimonials and payouts and are not guarantees of future performance or success. Our location is 40600 Ann Arbor Road East, Plymouth, MI, 48170.
        </RiskDisclosure>
        </PoweredByContainer>
      </ChartContainer>
    </>
  );
};

export default ChartSection;
