import React, { useState } from 'react';
import { useSale } from '../../SaleContext';
import { Link } from 'react-router-dom';
import {
  ServicesContainer,
  WhiteBar,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  SaleText,
  CardTitle,
  DetailContainer,
  DetailLabel,
  DetailValue,
  SubTitle,
  Tabs,
  Tab,
  ExpressCard,
  ExpressCardTitle,
  ExpressSubTitle,
  ExpressDetailContainer,
  ExpressDetailLabel,
  ExpressDetailValue,
  PopularTag,
  GoldCard,
  DiscountedPrice,
  OriginalPrice,
  DiscountPriceButton,
  ExpressPriceButton,
  GoldPriceButton

} from './ServiceElements';

const Services = () => {
  const { currentSale } = useSale(); // Retrieve the current sale from SaleContext
  const [activePath, setActivePath] = useState('standard');
  
  const standardAccountDetails = [
    // Current standard path account details
    {
      title: "$25,000",
      subtitle: "Bonus Capital $25,000 + $50",
      details: [
        { label: "STANDARD PATH", value: "" },
        { label: "Contracts:", value: "6 (5 Micros)" },
        { label: "Profit Goal:", value: "$1,250" },
        { label: "Trailing Threshold:", value: "$1,250" },
        { label: "Daily Drawdown:", value: "None" },
        { label: "Scaling Penalty:", value: "None" },
        { label: "QuantTower Integration:", value: "Available" },
        { label: "Real-Time Data Fees:", value: "Included" },
        { label: "Reduced profit target:", value: "Included" }
      ],
      price: "$159/MONTH"
    },
    {
      title: "$50,000",
      subtitle: "Bonus Capital $50,000 + $50",
      details: [
        { label: "STANDARD PATH", value: "" },
        { label: "Contracts:", value: "12 (5 Micros)" },
        { label: "Profit Goal:", value: "$2,500" },
        { label: "Trailing Threshold:", value: "$2,500" },
        { label: "Daily Drawdown:", value: "None" },
        { label: "Scaling Penalty:", value: "None" },
        { label: "QuantTower Integration:", value: "Available" },
        { label: "Real-Time Data Fees:", value: "Included" },
        { label: "Reduced profit target:", value: "Included" }
      ],
      price: "$181/MONTH"
    },
    {
      title: "$75,000",
      subtitle: "Bonus Capital $75,000 + $50",
      details: [
        { label: "STANDARD PATH", value: "" },
        { label: "Contracts:", value: "14 (5 Micros)" },
        { label: "Profit Goal:", value: "$3,500" },
        { label: "Trailing Threshold:", value: "$2,750" },
        { label: "Daily Drawdown:", value: "None" },
        { label: "Scaling Penalty:", value: "None" },
        { label: "QuantTower Integration:", value: "Available" },
        { label: "Real-Time Data Fees:", value: "Included" },
        { label: "Reduced profit target:", value: "Included" }
      ],
      price: "$200/MONTH"
    },
    {
      title: "$100,000",
      subtitle: "Bonus Capital $100,000 + $100",
      details: [
        { label: "STANDARD PATH", value: "" },
        { label: "Contracts:", value: "17 (5 Micros)" },
        { label: "Profit Goal:", value: "$5,500" },
        { label: "Trailing Threshold:", value: "$3,000" },
        { label: "Daily Drawdown:", value: "None" },
        { label: "Scaling Penalty:", value: "None" },
        { label: "QuantTower Integration:", value: "Available" },
        { label: "Real-Time Data Fees:", value: "Included" },
        { label: "Reduced profit target:", value: "Included" }
      ],
      price: "$220/MONTH"
    },
    {
      title: "$150,000",
      subtitle: "Bonus Capital $150,000 + $100",
      details: [
        { label: "STANDARD PATH", value: "" },
        { label: "Contracts:", value: "20 (5 Micros)" },
        { label: "Profit Goal:", value: "$8,000" },
        { label: "Trailing Threshold:", value: "$5,000" },
        { label: "Daily Drawdown:", value: "None" },
        { label: "Scaling Penalty:", value: "None" },
        { label: "QuantTower Integration:", value: "Available" },
        { label: "Real-Time Data Fees:", value: "Included" },
        { label: "Reduced profit target:", value: "Included" }
      ],
      price: "$310/MONTH"
    },
    {
      title: "$300,000",
      subtitle: "Bonus Capital $300,000 +$150",
      details: [
        { label: "STANDARD PATH", value: "" },
        { label: "Contracts:", value: "40 (5 Micros)" },
        { label: "Profit Goal:", value: "$19,000" },
        { label: "Trailing Threshold:", value: "$7,500" },
        { label: "Daily Drawdown:", value: "None" },
        { label: "Scaling Penalty:", value: "None" },
        { label: "QuantTower Integration:", value: "Available" },
        { label: "Real-Time Data Fees:", value: "Included" },
        { label: "Reduced required profit target:", value: "Included" }
      ],
      price: "$654/MONTH"
    }
  ];

  const expressAccountDetails = [
    {
      title: "$25,000",
      subtitle: "Express Funding Path",
      details: [
        { label: "Price:", value: "$65 Monthly" },
        { label: "Contracts:", value: "4 (2 Micros)" },
        { label: "Profit Goal:", value: "$1,250" },
        { label: "Trailing Threshold:", value: "$1,250" },
        { label: "Activation Fee:", value: "None" },
        { label: "Payout Options:", value: "Same Day or 3-Month Spread" },
        { label: "Consistency Rule:", value: "Only Evaluation Account" },
        { label: "Free Reset Fee:", value: "1 Per Month" }
      ],
      price: "$65 MONTHLY"
    },
    {
      title: "$50,000",
      subtitle: "Express Funding Path",
      details: [
        { label: "Price:", value: "$80 Monthly" },
        { label: "Contracts:", value: "6 (5 Micros)" },
        { label: "Profit Goal:", value: "$2,500" },
        { label: "Trailing Threshold:", value: "$2,500" },
        { label: "Activation Fee:", value: "None" },
        { label: "Payout Options:", value: "Same Day or 3-Month Spread" },
        { label: "Consistency Rule:", value: "Only Evaluation Account" },
        { label: "Free Reset Fee:", value: "1 Per Month" }
      ],
      price: "$80 MONTHLY"
    },
    {
      title: "$150,000",
      subtitle: "Express Funding Path",
      details: [
        { label: "Price:", value: "$210 Monthly" },
        { label: "Contracts:", value: "12 (5 Micros)" },
        { label: "Profit Goal:", value: "$8,000" },
        { label: "Trailing Threshold:", value: "$5,000" },
        { label: "Activation Fee:", value: "None" },
        { label: "Payout Options:", value: "Same Day or 3-Month Spread" },
        { label: "Consistency Rule:", value: "Only Evaluation Account" },
        { label: "Reset Fee:", value: "100" }
      ],
      price: "$210 MONTHLY"
    }
  ];

  const instantFundingDetails = [
    {
      title: "$25,000",
      subtitle: "Instant Funding Path",
      details: [
        { label: "Price:", value: "$210" },
        { label: "Contracts:", value: "6 (3 Micros)" },
        { label: "Profit Goal:", value: "$1,250" }, // Keep original profit goal
        { label: "Trailing Threshold:", value: "$1,000" }, // Keep original trailing threshold
        { label: "Purchase:", value: "One Time Fee" },
        { label: "Drawdown:", value: "Trailing" },
        { label: "Minimum Trading Days:", value: "8" },
        { label: "Payout Options:", value: "Same Day or 3-Month Spread" },
        { label: "Consistency Rule:", value: "Yes" }
      ],
      price: "$210 ONE-TIME FEE"
    },
    {
      title: "$50,000",
      subtitle: "Instant Funding Path",
      details: [
        { label: "Price:", value: "$500" },
        { label: "Contracts:", value: "6 (5 Micros)" },
        { label: "Profit Goal:", value: "$2,500" }, // Keep original profit goal
        { label: "Trailing Threshold:", value: "$2,500" }, // Keep original trailing threshold
        { label: "Purchase:", value: "One Time Fee" },
        { label: "Drawdown:", value: "Trailing" },
        { label: "Minimum Trading Days:", value: "8" },
        { label: "Payout Options:", value: "Same Day or 3-Month Spread" },
        { label: "Consistency Rule:", value: "Yes" }
      ],
      price: "$500 ONE-TIME FEE"
    },
    {
      title: "$150,000",
      subtitle: "Instant Funding Path",
      details: [
        { label: "Price:", value: "$700" },
        { label: "Contracts:", value: "10 (5 Micros)" },
        { label: "Profit Goal:", value: "$8,000" }, // Keep original profit goal
        { label: "Trailing Threshold:", value: "$5,000" }, // Keep original trailing threshold
        { label: "Purchase:", value: "One Time Fee" },
        { label: "Drawdown:", value: "Trailing" },
        { label: "Minimum Trading Days:", value: "8" },
        { label: "Payout Options:", value: "Same Day or 3-Month Spread" },
        { label: "Consistency Rule:", value: "Yes" }
      ],
      price: "$700 ONE-TIME FEE"
    }
  ];
  
  const calculateDiscountedPrice = (price) => {
    // Extract numerical part of the price
    const numericPrice = parseFloat(price.replace(/[^0-9.]/g, ''));
    const discountedPrice = (numericPrice * 0.1).toFixed(2); // 90% off
    return `$${discountedPrice}`;
  };


  const renderStandardCards = () => {
    return standardAccountDetails.map((account, index) => (
      <ServiceCard
        key={index}
        title={account.title}
        subtitle={account.subtitle}
        details={account.details}
        originalPrice={account.price}  // Original price
        discountPrice={calculateDiscountedPrice(account.price)}  // Discounted price
        currentSale={currentSale}
      />
    ));
  };
  
  const renderExpressCards = () => {
    return expressAccountDetails.map((account, index) => (
      <ExpressCard key={index}>
        {account.title === "$50,000" && <PopularTag>MOST POPULAR</PopularTag>}
        <ExpressCardTitle>{account.title}</ExpressCardTitle>
        <ExpressSubTitle>{account.subtitle}</ExpressSubTitle>
        {account.details.map((detail, i) => (
          <ExpressDetailContainer key={i}>
            <ExpressDetailLabel>{detail.label}</ExpressDetailLabel>
            <ExpressDetailValue>{detail.value}</ExpressDetailValue>
          </ExpressDetailContainer>
        ))}
        <Link to="/signup/signin">
          <ExpressPriceButton>{account.price}</ExpressPriceButton>
        </Link>
      </ExpressCard>
    ));
  };

  // Render Instant Funding cards without discount logic
  const renderInstantFundingCards = () => {
    return instantFundingDetails.map((account, index) => (
      <GoldCard key={index}>
        <ExpressCardTitle>{account.title}</ExpressCardTitle>
        <ExpressSubTitle>{account.subtitle}</ExpressSubTitle>
        {account.details.map((detail, i) => (
          <ExpressDetailContainer key={i}>
            <ExpressDetailLabel>{detail.label}</ExpressDetailLabel>
            <ExpressDetailValue>{detail.value}</ExpressDetailValue>
          </ExpressDetailContainer>
        ))}
        <Link to="/signup/signin">
          <GoldPriceButton>{account.price}</GoldPriceButton>
        </Link>
      </GoldCard>
    ));
  };
  

  return (
    <ServicesContainer id="plans">
      <ServicesH1>OUR PRODUCTS</ServicesH1>
      <Tabs>
        <Tab active={activePath === 'standard'} onClick={() => setActivePath('standard')}>
          Standard Path
        </Tab>
        <Tab active={activePath === 'express'} onClick={() => setActivePath('express')}>
          Express Path
        </Tab>
        <Tab active={activePath === 'instant'} onClick={() => setActivePath('instant')}>
          Instant Funding
        </Tab>
      </Tabs>
      <ServicesWrapper>
        {activePath === 'standard' && renderStandardCards()}
        {activePath === 'express' && renderExpressCards()}
        {activePath === 'instant' && renderInstantFundingCards()}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

const ServiceCard = ({ title, subtitle, details, originalPrice, discountPrice, currentSale }) => {
  return (
    <ServicesCard active={currentSale.active}>
      {currentSale.active && <SaleText>{currentSale.discount}% OFF SALE</SaleText>}
      <CardTitle>{title}</CardTitle>
      <SubTitle>{subtitle}</SubTitle>
      <WhiteBar />
      {details.map((detail, index) => (
        <DetailContainer key={index}>
          <DetailLabel>{detail.label}</DetailLabel>
          <DetailValue>{detail.value}</DetailValue>
        </DetailContainer>
      ))}
      <Link to="/signup/signin">
        <DiscountPriceButton theme="bronze">
          <OriginalPrice>{originalPrice}</OriginalPrice>  {/* Original price */}
          <DiscountedPrice theme="bronze">{discountPrice}</DiscountedPrice>  {/* Discounted price */}
        </DiscountPriceButton>
      </Link>
    </ServicesCard>
  );
};


export default Services;


